const menu = document.querySelector(".header__links");
const menuBtn = document.querySelector(".menu__icon");
const body = document.body;
if (menu && menuBtn) {
	menuBtn.addEventListener('click', () => {
		menu.classList.toggle('active');
		menuBtn.classList.toggle('active');
		body.classList.toggle('lock');
	})
	menu.querySelectorAll(".header__link").forEach(link => {
		link.addEventListener('click', () => {
			menu.classList.remove('active');
			menuBtn.classList.remove('active');
			body.classList.remove('lock');
		})
	})
}

const anchors = document.querySelectorAll('a[href*="#"]');
anchors.forEach(link => {
	link.addEventListener('click', event => {
		event.preventDefault();
		const blockID = link.getAttribute('href').substring(1);
		document.getElementById(blockID).scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		})
	})
})